import React, { useContext, useState } from "react"
import { useForm } from "react-hook-form"
import * as St from "../Styled"
import { NotificationManager } from "react-notifications"
import { AppContext } from "../../contexts/AppContext"
import { useMutation } from "@apollo/client"
import {
  ADD_STATION_PRESENTER,
  MUTATION_UPDATE_PRESENTER,
} from "../../queries/page/presenter"

import axios from "axios"
import auth from "../../auth/auth"

import { awsStationPresenterPath } from "../../constants/props"
import useButtonLoader from "../../hooks/useButtonLoader"
import ProgressContent from "../ProgressBar"

const basePrefix = `${process.env.STRAPI_SERVER_URL}`

const PresenterForm = props => {
  let { device, userState } = useContext(AppContext)
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: props.formValues,
  })
  const [addPreseter] = useMutation(ADD_STATION_PRESENTER)
  const [updatePresenter] = useMutation(MUTATION_UPDATE_PRESENTER)
  const [coverImage, setCoverImage] = useState({ preview: "", raw: "" })
  const [progressOfImage, setProgressOfImage] = useState({
    count: 0,
    title: "",
  })
  const handleChange = e => {
    if (e.target.files.length) {
      setCoverImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        name: e.target.files[0].name,
      })
    }
  }

  const [loaderElement, setLoader] = useButtonLoader(
    props.isEdit ? "Update Presenter" : "Add Presenter",
    "Processing..."
  )

  const updatePresenterData = (data, where) => {
    return updatePresenter({
      variables: {
        input: {
          data,
          where,
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
  }

  const showNotification = (msg, success) => {
    success ? NotificationManager.success(msg) : NotificationManager.error(msg)
    setLoader(false)
    if (success) {
      // reset and hide form if success
      reset()
      props.setShowForm({
        show: false,
        edit: false,
      })
      // as data is updated or created force fetch data from server
      props.setForceFetch(true)
    }
  }

  const uploadCoverImage = (coverImageFile, id, presenterName) => {
    // cover image of audio file was updated during update operation

    let presenterPath =
      userState && userState.station && userState.station.title
        ? awsStationPresenterPath(userState.station.title, presenterName)
        : ""

    const coverFormData = new FormData()
    coverFormData.append("files", coverImageFile)
    coverFormData.append("ref", "presenter")
    coverFormData.append("refId", id)
    coverFormData.append("field", "image")
    coverFormData.append("path", presenterPath)
    return axios.post(`${basePrefix}/upload`, coverFormData, {
      headers: {
        Authorization: "Bearer " + auth.getToken(),
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        setProgressOfImage({
          count: percentCompleted - 0.05 * percentCompleted,
          title: "Uploading " + coverImage.name,
        })
      },
    })
  }

  const onSubmit = values => {
    setLoader(true)
    // First upload cover image
    if (values.id) {
      // Audio is updated by user
      setLoader(true)
      // Update basic data in collection first
      updatePresenterData(
        {
          title: values.title,
          contact: values.contact,
          about: values.about,
        },
        {
          id: values.id,
        }
      ).then(() => {
        if (coverImage.raw) {
          uploadCoverImage(coverImage.raw, values.id, values.title)
            .then(response => {
              showNotification("Presenter data changed ...", true)
              setProgressOfImage({
                count: 100,
                title: "Image Successfully Uploaded",
              })
              setLoader(false)
            })
            .catch(() => {
              showNotification("Cover image upload failed...", false)
              setLoader(false)
            })
        } else {
          showNotification("Presenter data changed ...", true)
          setLoader(false)
        }
      })
    } else {
      addPreseter({
        variables: {
          input: {
            data: {
              title: values.title,
              contact: values.contact,
              about: values.about,
              station: userState.stationID,
              owner: userState.id,
            },
          },
        },
        context: {
          headers: {
            Authorization: "Bearer " + auth.getToken(),
          },
        },
      })
        .then(presenterData => {
          if (
            presenterData.data &&
            presenterData.data.createPresenter &&
            presenterData.data.createPresenter.presenter &&
            presenterData.data.createPresenter.presenter.id
          ) {
            if (coverImage.raw) {
              uploadCoverImage(
                coverImage.raw,
                presenterData.data.createPresenter.presenter.id,
                values.title
              )
                .then(response => {
                  showNotification("Presenter data changed ...", true)
                  setProgressOfImage({
                    count: 100,
                    title: "Image Successfully Uploaded",
                  })
                  setLoader(false)
                })
                .catch(() => {
                  showNotification("Cover image upload failed...", false)
                  setLoader(false)
                })
            } else {
              NotificationManager.success("Presenter Added...")
              setLoader(false)
            }
          }
        })
        .catch(() => {
          NotificationManager.error("Presenter Addition failed...", false)
          setLoader(false)
        })
    }
  }

  const formValues = props.formValues

  let coverImageURL =
    formValues && formValues.image && formValues.image.url
      ? !formValues.image.url.startsWith("http")
        ? basePrefix + formValues.image.url
        : formValues.image.url
      : undefined

  return (
    <St.Form
      ismobile={device === "mobile"}
      onSubmit={handleSubmit(onSubmit)}
      enctype="multipart/form-data"
    >
      <div>
        {formValues && formValues.id && (
          <St.FormInput
            name="id"
            type="hidden"
            ref={register({
              required: "Required",
            })}
            defaultValue={formValues.id}
          />
        )}
        <St.Label>Name</St.Label>
        <St.FormInput
          name="title"
          aria-invalid={errors.title ? "true" : "false"}
          aria-describedby="titleError"
          ref={register({
            required: "Please add presenter's name",
          })}
        />
        {errors.title && <St.ErrorText>{errors.title.message}</St.ErrorText>}
      </div>
      <div>
        <St.Label>Cover Image (Recommended Size: 120 X 120 pixels)</St.Label>
        <St.FileTypeLabel htmlFor="upload-cover">
          {coverImage.preview ? (
            <St.Image src={coverImage.preview} alt="dummy"></St.Image>
          ) : coverImageURL ? (
            <St.Image src={coverImageURL} alt="dummy"></St.Image>
          ) : (
            <>Click here to Upload Cover Image</>
          )}
        </St.FileTypeLabel>
        <St.FormInputFile
          id="upload-cover"
          name="coverImage"
          aria-invalid={errors.coverImage ? "true" : "false"}
          aria-describedby="coverImageError"
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleChange}
          ref={
            !coverImageURL
              ? register({
                  required: "Please select cover image for this presenter",
                })
              : undefined
          }
        />
        {errors.coverImage && (
          <St.ErrorText>{errors.coverImage.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>Contact Number</St.Label>
        <St.FormInput
          name="contact"
          aria-invalid={errors.contact ? "true" : "false"}
          aria-describedby="presenterContactError"
          ref={register({
            required: "Please add contact number of this presenter",
          })}
          defaultValue={formValues ? formValues.contact : undefined}
        />
        {errors.contact && (
          <St.ErrorText>{errors.contact.message}</St.ErrorText>
        )}
      </div>
      <div>
        <St.Label>About</St.Label>
        <St.FormTextarea
          name="about"
          aria-invalid={errors.about ? "true" : "false"}
          aria-describedby="aboutError"
          ref={register({
            required: "Please add something about this presenter",
          })}
          defaultValue={formValues ? formValues.about : undefined}
        />
        {errors.about && <St.ErrorText>{errors.about.message}</St.ErrorText>}
      </div>

      <St.SubmitButton ref={loaderElement}>Submit</St.SubmitButton>
      {progressOfImage.count < 100 && (
        <ProgressContent
          progress={progressOfImage.count}
          title={progressOfImage.title}
        />
      )}
    </St.Form>
  )
}

export default PresenterForm
