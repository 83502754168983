import React from "react"
import styled from "styled-components"
import iconEdit from "../../images/pages/edit.png"
import iconDelete from "../../images/pages/delete.png"
import ConfirmDialog from "../Modal/ConfirmDialog"
import useModal from "../Modal/useModal"

import {
  PROP_FONT_PRIMARY,
  PROP_FONT_SECONDARY,
} from "../../constants/props"

import {
  MOBILE,
  LANDSCAPE,
  TAB,
  MEDIUM,
  LARGE,
} from "../../constants/breakpoints"

import * as St from "../Styled"

const PresneterCardWrappper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  & div {
    align-self: center;
  }

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.presenters.slider.cardWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.presenters.slider.cardWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.presenters.slider.cardWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.presenters.slider.cardWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.presenters.slider.cardWidth}px;
  }
`

const PresenterImg = styled.img`
  border-radius: 50%;

  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.presenters.slider.image.widthHeight}vw;
    height: ${MOBILE.presenters.slider.image.widthHeight}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.presenters.slider.image.widthHeight}vw;
    height: ${LANDSCAPE.presenters.slider.image.widthHeight}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.presenters.slider.image.widthHeight}px;
    height: ${TAB.presenters.slider.image.widthHeight}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.presenters.slider.image.widthHeight}px;
    height: ${MEDIUM.presenters.slider.image.widthHeight}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.presenters.slider.image.widthHeight}px;
    height: ${LARGE.presenters.slider.image.widthHeight}px;
  }
`
const Title = styled.div`
  font-family: ${PROP_FONT_PRIMARY};
  padding: 0.6vw 0 0 0;
  text-overflow: ellipsis;
  text-align: center;

  @media (max-width: ${MOBILE.maxWidth}px) {
    font-size: ${MOBILE.presenters.slider.font.title}px;
    width: ${MOBILE.presenters.slider.cardWidth}vw;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    font-size: ${LANDSCAPE.presenters.slider.font.title}px;
    width: ${LANDSCAPE.presenters.slider.cardWidth}vw;
  }
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.presenters.slider.font.title}px;
    width: ${TAB.presenters.slider.cardWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.presenters.slider.font.title}px;
    width: ${MEDIUM.presenters.slider.cardWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    font-size: ${LARGE.presenters.slider.font.title}px;
    width: ${LARGE.presenters.slider.cardWidth}px;
  }
`
const Subtitle = styled.div`
  list-style: none;
  text-overflow: ellipsis;
  overflow: visible;
  font-family: ${PROP_FONT_SECONDARY};
  font-style: italic;
  text-align: center;
  @media (max-width: ${MOBILE.maxWidth}px) {
    width: ${MOBILE.presenters.slider.cardWidth}vw;
    font-size: ${MOBILE.presenters.slider.font.content}px;
  }
  @media (min-width: ${LANDSCAPE.minWidth}px) {
    width: ${LANDSCAPE.presenters.slider.cardWidth}vw;
    font-size: ${LANDSCAPE.presenters.slider.font.content}px;
  }
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.presenters.slider.cardWidth}px;
    font-size: ${TAB.presenters.slider.font.content}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.presenters.slider.cardWidth}px;
    font-size: ${MEDIUM.presenters.slider.font.content}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.presenters.slider.cardWidth}px;
    font-size: ${LARGE.presenters.slider.font.content}px;
  }
`

const PresenterCard = ({
  id,
  shows,
  image,
  setShowForm,
  setFormValues,
  title,
  data,
  removeItem,
}) => {
  const { isShowing, toggle } = useModal()

  const showsString =
    shows && shows.length
      ? shows.length > 1
        ? shows[0].title + ", " + shows[1].title
        : shows[0].title
      : ""
  return (
    <PresneterCardWrappper>
      <ConfirmDialog
        isShowing={isShowing}
        confirmed={removeItem}
        cancelled={toggle}
        message={"item"}
        data={{ id }}
      />
      <PresenterImg src={image && image.url ? image.url : ""} />

      <Title>{title}</Title>
      <Subtitle>{showsString}</Subtitle>
      <St.OperationWrapper>
        <St.OperationIcon
          src={iconEdit}
          onClick={() => {
            setShowForm({
              show: true,
              edit: true,
            })
            setFormValues(data)
          }}
        />
        <St.OperationIcon
          src={iconDelete}
          onClick={() => {
            toggle()
          }}
        />
      </St.OperationWrapper>
    </PresneterCardWrappper>
  )
}

export default PresenterCard
