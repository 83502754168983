import React, { useContext, useState } from "react"
import { QUERY_ALL_STATION_PRESENTER } from "../../queries/page/presenter"
import { AppContext } from "../../contexts/AppContext"
import { CARD_TYPE_PRESENTER, getConfigValue } from "../../constants/props"
import {
  MOBILE,
  MEDIUM,
  LARGE,
  TAB,
  LANDSCAPE,
  DEVICE_MOBILE,
} from "../../constants/breakpoints"
import { useMutation, useQuery } from "@apollo/client"
import auth from "../../auth/auth"
import { NotificationManager } from "react-notifications"
import { NoDataMessage } from "../Styled"
import SliderContainer from "../SliderContainer"
import UserPresenterCard from "./UserPresenterCard"

import { MUTATION_DELETE_PRESENTER } from "../../queries/page/presenter"

const SliderWrapper = props => {
  const [deleteMedia] = useMutation(MUTATION_DELETE_PRESENTER)

  const removeItem = data => {
    return deleteMedia({
      variables: {
        input: {
          where: {
            id: data.id,
          },
        },
      },
      context: {
        headers: {
          Authorization: "Bearer " + auth.getToken(),
        },
      },
    })
      .then(response => {
        NotificationManager.success("Presenter removed...")
        // returnn true so that confirmation dialog closes
        props.setForceFetch(true)
        return true
      })
      .catch(() => {
        NotificationManager.error("Operation failed...")
        // returnn true so that confirmation dialog closes
        return true
      })
  }

  let { device } = useContext(AppContext)
  let slidesToShow = getConfigValue(device, "presenters.slider.slides")

  const [state, setState] = useState({
    data: props.data,
    showLeftArrow: false,
    showRightArrow: props.data.length - slidesToShow <= 0 ? false : true,
  })

  let settings = {
    infinite: false,
    speed: 150,
    slidesToShow: LARGE.presenters.slider.slides,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: false,
    lazyLoad: false,
    arrows: false,
    responsive: [
      {
        breakpoint: LARGE.minWidth,
        settings: {
          slidesToShow: MEDIUM.presenters.slider.slides,
        },
      },
      {
        breakpoint: MEDIUM.minWidth,
        settings: {
          slidesToShow: TAB.presenters.slider.slides,
        },
      },
      {
        breakpoint: LANDSCAPE.minWidth,
        settings: {
          slidesToShow: MOBILE.presenters.slider.slides,
          initialSlide: state.data.length > 0 ? 1 : 0,
          className: "sibling-visible", // would make overflow visible and hence would allow displaying parts of siblings
        },
      },
    ],
    afterChange: i => {
      setState({
        ...state,
        showLeftArrow: i > 0 ? true : false,
        showRightArrow: state.data.length - slidesToShow - i > 0 ? true : false,
      })
    },
  }

  return props.data.length > 0 ? (
    <SliderContainer
      addPadding={
        device === DEVICE_MOBILE &&
        props.data.length > MOBILE.presenters.slider.slides
          ? true
          : false
      }
      settings={settings}
      showLeftArrow={state.showLeftArrow}
      showRightArrow={state.showRightArrow}
      type={CARD_TYPE_PRESENTER}
    >
      {props.data.map((item, i) => (
        <UserPresenterCard
          key={i}
          {...item}
          data={item}
          setShowForm={props.setShowForm}
          setFormValues={props.setFormValues}
          removeItem={removeItem}
          hover={false}
        />
      ))}
    </SliderContainer>
  ) : (
    <NoDataMessage>No presenters to show</NoDataMessage>
  )
}

// component that shows user audio list
const PresenterList = props => {
  let { userState } = useContext(AppContext)

  // use fetchPolicy = 'network-only' to force fetch
  const { data, loading } = useQuery(
    QUERY_ALL_STATION_PRESENTER,
    {
      variables: {
        userID: userState.id,
      },
      fetchPolicy: props.forceFetch ? "network-only" : undefined,
    }
  )

  if (props.forceFetch) {
    // if force fetched in previous attempt reset flag
    props.setForceFetch(false)
  }

  return !loading ? (
    <>
      <SliderWrapper
        data={data.presenters}
        setShowForm={props.setShowForm}
        setFormValues={props.setFormValues}
        setForceFetch={props.setForceFetch}
      />
    </>
  ) : (
    <div>Loading...</div>
  )
}

export default PresenterList
