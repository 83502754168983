import React, { useContext } from "react"
import { AppContext } from "../contexts/AppContext"
import ContentStationPresenter from "../components/ContentStationPresenter"
import { ROLE_STATIONADMIN } from "../constants/props"
import NotAuthorized from "../components/NotAuthorized"

const StationPresenter = () => {
  let { userState } = useContext(AppContext)
  if (
    userState &&
    userState.role &&
    (userState.role.type === ROLE_STATIONADMIN)
  ) {
    return (
      <React.Fragment>
            <ContentStationPresenter />
        </React.Fragment>
    )
  } else {
    return <NotAuthorized>You are not authorized to see contents on this page</NotAuthorized>
  }
}

export default StationPresenter
